<script>
import calendar from "@/components/Calendars.vue";
import reportSales from "@/components/printers/reports/SalesReportThermal.vue";
import mixin from "@/mixins";
import ListData from "./components/ListDataItems";
import Print from "./components/PrintDataItems";
export default {
  components: {
    calendar,
    reportSales,
    ListData,
    Print,
  },
  mixins: [mixin],
  data: () => ({
    type: "TODOS",
    dialog: false,
    headers: [
      { text: "CÓD. BARRA", value: "COD_BARRAS", sortable: false },
      { text: "Data/Hora", value: "DATA_HORA", sortable: false },
      { text: "Remetente", value: "REMETENTE", sortable: false },
      { text: "Destinatário", value: "DESTINATARIO", sortable: false },
      { text: "Origem", value: "ORIGEM", sortable: false },
      { text: "Destino", value: "DESTINO", sortable: false },
      { text: "Cobrança", value: "COBRANCA", sortable: false },
      { text: "Valor", value: "TOTAL", sortable: false },
      { text: "Status", align: "right", value: "action", sortable: false },
    ],
    search: {
      dateStart: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateEnd: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      type_filter: "AGENCIAS",
      item: null,
      cobranca: 1,
    },
    items: [],
    cobrancas: [
      { id: null, description: "TODOS" },
      { id: 1, description: "PAGOS" },
      { id: 2, description: "A PAGAR" },
      // { id: 3, description: "CONTRATO" },
    ],
    agencia_motoristas: [],
    valid: true,
    total: 0,
    total_pago: 0,
    total_a_pagar: 0,
    comissao: 0,
    fieldRules: [(v) => !!v || "Este campo é requerido"],
  }),
  created() {
    this.mixin;
  },
  methods: {
    printItem() {
      this.$htmlToPaper("printReport");
    },
    checkFilter() {
      if (this.type == "TODOS") {
        this.search.item = null;
      }
    },
    async loadCombobox(cobranca = null) {
      if (!cobranca || cobranca === 1) {
        this.loadFilter();
        return;
      }
      const request = await this.$http.post(
        "/encomendas/relatorio/filter",
        this.search
      );
      this.agencia_motoristas = request.data;
      this.$store.dispatch("activeLoader", false);
    },
    editCobranca() {
      const c = confirm(`Confirma o pagamento das encomendas selecionadas?`);
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http
          .post(
            `/encomendas/edit/status_pagamentos`,
            this.$store.getters.getselectedPackpages
          )
          .then((r) => {
            this.$store.dispatch("showMessage", {
              show: true,
              color: "success darken-1",
              text: r.data.success,
            });
            this.$store.dispatch("clearSelectedPackpages", []);
            this.loadValues();
          })
          .catch((err) => {
            this.$store.dispatch("showMessage", {
              show: true,
              color: "error",
              text: err.message,
            });
            this.$store.dispatch("activeLoader", false);
          });
      }
    },
    loadValues() {
      this.items = [];
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post(`/relatorios/encomendas`, {
          dateStart: this.search.dateStart,
          dateEnd: this.search.dateEnd,
          type_filter: this.search.type_filter,
          item: this.search.item,
          cobranca: this.search.cobranca,
        })
        .then((req) => {
          this.items = req.data;
          this.total = 0;
          this.total_pago = 0;
          this.total_a_pagar = 0;
          this.total = req.data.reduce((acc, item) => {
            return acc + item.VALOR_TOTAL;
          }, 0);
          req.data.map((item) => {
            item.DATA.map((newItem) => {
              this.total_pago +=
                newItem.COBRANCA == 1 ? parseFloat(newItem.TOTAL) : 0;
              this.total_a_pagar +=
                newItem.COBRANCA == 2 || newItem.COBRANCA == 3
                  ? parseFloat(newItem.TOTAL)
                  : 0;
            });
          });

          this.total = req.data.reduce((acc, item) => {
            return acc + item.VALOR_TOTAL;
          }, 0);
          this.$store.dispatch("activeLoader", false);
        })
        .catch((err) => {
          this.$store.dispatch("activeLoader", false);
        });
    },
    async loadFilter() {
      this.agencia_motoristas = [];
      this.$store.dispatch("activeLoader", true);
      if (this.search.type_filter == "AGENCIAS") {
        const agencias = await this.$http.get("/agencias");
        this.agencia_motoristas = agencias.data;
        this.$store.dispatch("activeLoader", false);
      } else if (this.search.type_filter == "MOTORISTAS") {
        const motoristas = await this.$http.get("/motoristas/ativos");
        this.agencia_motoristas = motoristas.data;
        this.$store.dispatch("activeLoader", false);
      } else if (this.search.type_filter == "CLIENTES") {
        const clientes = await this.$http.get("/encomendas/clientes");
        this.agencia_motoristas = clientes.data;
        this.$store.dispatch("activeLoader", false);
      }
    },
  },
};
</script>
