<template>
  <v-card class="mb-10" flat>
    <v-card-text v-for="(item, ind) in items" :key="ind">
      <v-row dense>
        <h3 class="mb-2">{{ item.NOME }}</h3>
        <v-spacer />
        <strong> SUBTOTAL: {{ convertCurrent(item.VALOR_TOTAL) }} </strong>
      </v-row>
      <v-divider />
      <v-data-table
        v-model="$store.state.selectedPackpages"
        :headers="headers"
        :show-select="checkStatusPaymentShowMainSelectGrid(item.DATA)"
        :items="item.DATA"
        item-key="ENCOMENDA_ID"
        class="elevation-0"
      >
        <template v-slot:item.TOTAL="{ item }">
          {{ convertCurrent(item.TOTAL) }}
        </template>
        <template v-slot:item.DATA_HORA="{ item }">
          {{ convertDateTimeNew(item.DATA_HORA) }}
        </template>
        <template v-slot:item.COBRANCA="{ item }">
          {{ selectCobranca(item.COBRANCA) }}
        </template>
        <template v-slot:item.action="{ item }">
          <v-tooltip
            bottom
            color="yellow darken-4"
            v-if="item.STATUS_PAGAMENTO == false"
          >
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="yellow darken-4"
                >mdi-clock-outline</v-icon
              >
            </template>
            <span>Aguardando Pagamento</span>
          </v-tooltip>
          <v-tooltip bottom color="blue darken-3" v-else>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" color="blue darken-1">mdi-check-all</v-icon>
            </template>
            <span>Pagamento confirmado</span>
          </v-tooltip>
        </template>
        <template v-slot:item.data-table-select="{ item, isSelected, select }">
          <v-simple-checkbox
            :value="isSelected"
            v-if="item.STATUS_PAGAMENTO == 0"
            @input="select($event)"
          />
        </template>
        <template v-slot:item.COD_BARRAS="{ item }">
          <MovimentacaoEncomendas :codBarras="item.COD_BARRAS" />
        </template>
      </v-data-table>
      <v-divider />
    </v-card-text>
  </v-card>
</template>

<script>
import mixin from "@/mixins";
import MovimentacaoEncomendas from "../../../../../components/MovimentacaoEncomendas.vue";
export default {
  props: ["items", "headers"],
  mixins: [mixin],
  methods: {
    checkStatusPaymentShowMainSelectGrid(items) {
      console.log(items);
      const isShowMainSelect = items.filter(
        (item) => item.STATUS_PAGAMENTO == 0
      );
      return isShowMainSelect.length > 0 ? true : false;
    },
  },
  components: { MovimentacaoEncomendas },
};
</script>
